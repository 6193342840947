import { Paper, styled } from '@mui/material';
import { forwardRef, RefObject } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Purchase } from '../../../types/purchase';
import ReceiptItem from './ReceiptItem';
import ReceiptProductItems from './ReceiptProductItems';
import { parsePrice } from '../../../util/function';
import ReceiptVats from './ReceiptVats';

const ReceiptItemWrapper = styled('div')`
    margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const ReceiptItemHeaderWrapper = styled('div')`
    margin-bottom: ${({ theme }) => theme.spacing(2)};

    // Add spacing below third item
    div:nth-of-type(3) {
        margin-bottom: ${({ theme }) => theme.spacing(1)};
    }
`;

const ReceiptItemSmall = styled(ReceiptItem)`
    p {
        font-size: 10px;
    }
`;

const ReceiptItemBold = styled(ReceiptItem)`
    p {
        font-weight: bold;
    }
`;

const ReceiptItemDate = styled(ReceiptItem)`
    p {
        font-size: 17px;
    }
`;

const ReceiptItemThanks = styled(ReceiptItem)`
    p {
        font-size: 20px;
    }
`;

const StyledPaper = styled(Paper)`
    padding: ${({ theme }) => theme.spacing(3)};
    max-width: calc(100vw - 32px);
`;

type ReceiptContentProps = {
    purchase: Purchase;
};

const ReceiptContent = forwardRef(({ purchase }: ReceiptContentProps, ref) => {
    const {
        header,
        purchaseDate,
        purchaseId,
        transactionReference,
        currencyCode,
        products,
        subtotalPrice,
        totalDiscount,
        totalPrice,
        totalVatAmount,
        totalPriceExVat,
        vatAmounts,
    } = purchase || {};

    const {
        name,
        fridgeName,
        facility,
        address,
        zipPostalCode,
        city,
        stateProvince,
        country,
        vatNumber,
        companyRegistrationNumber,
    } = header || {};

    const { t, i18n } = useTranslation();

    const registrationNumber =
        companyRegistrationNumber &&
        `${t('receiptPageCompanyRegistrationNumber')} ${String(companyRegistrationNumber)}`;

    const vat = vatNumber && `${t('receiptPageVATNumber')} ${String(vatNumber)}`;

    // Concatenated string seperated by comma
    const companyInfo = [registrationNumber, vat].filter(e => e).join(', ');

    return (
        <StyledPaper elevation={3} ref={ref as RefObject<HTMLDivElement>}>
            <ReceiptItemHeaderWrapper>
                <ReceiptItemSmall name={name} />
                <ReceiptItemSmall name={companyInfo} />
                {/* Concatenated string seperated by comma */}
                <ReceiptItemSmall
                    name={[address, zipPostalCode, stateProvince, city, country]
                        .filter(e => e)
                        .join(', ')}
                />
                <ReceiptItemSmall
                    name={`${t('receiptPageFacility')}: ${String(facility ?? '-')}`}
                />
                <ReceiptItemSmall
                    name={`${t('receiptPageFridge')}: ${String(fridgeName ?? '-')}`}
                />
                <ReceiptItemSmall
                    name={`${t('receiptPagePurchaseId')}: ${String(purchaseId ?? '-')}`}
                />
                {transactionReference && (
                    <ReceiptItemSmall
                        name={`${t('receiptPageTransactionReference')}: ${String(
                            transactionReference ?? '-'
                        )}`}
                    />
                )}
            </ReceiptItemHeaderWrapper>
            <ReceiptItemWrapper>
                <ReceiptItemDate name={dayjs(purchaseDate).locale(i18n.language).format('llll')} />
            </ReceiptItemWrapper>
            {products && !!products.length && currencyCode && (
                <ReceiptProductItems products={products} currencyCode={currencyCode} />
            )}
            {currencyCode && (
                <ReceiptItemWrapper>
                    <strong>
                        <ReceiptItemBold
                            name={t('receiptPageSubtotal')}
                            value={parsePrice(subtotalPrice, currencyCode, i18n)}
                        />
                    </strong>
                    {!!totalDiscount && (
                        <strong>
                            <ReceiptItemBold
                                name={t('receiptPageDiscount')}
                                value={`- ${parsePrice(totalDiscount, currencyCode, i18n)}`}
                            />
                        </strong>
                    )}
                    <strong>
                        <ReceiptItemBold
                            name={t('receiptPageTotal')}
                            value={parsePrice(totalPrice, currencyCode, i18n)}
                        />
                    </strong>
                </ReceiptItemWrapper>
            )}
            <ReceiptVats
                totalVatAmount={totalVatAmount}
                totalPriceExVat={totalPriceExVat}
                totalPrice={totalPrice}
                vatAmounts={vatAmounts}
                currencyCode={currencyCode}
            />
            <ReceiptItemThanks name={t('receiptPageThanks')} />
        </StyledPaper>
    );
});

export default ReceiptContent;
